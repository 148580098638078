<template>
  <!-- 数据看板>分公司看板 -->
  <div class="dict">
    <div class="boardTitle">分公司看板</div>
    <ul class="top">
      <li class="topLeft">
        <!-- <div class="modelTitle">支出预算执行率</div> -->
        <ul>
          <li>
            <div class="leftIcon">
              <img src="@/assets/img/board/food.png" alt="" />
            </div>
            <div class="rightDetail">
              <div class="rate">{{ expendRate.wages }}%</div>
              <div class="rateTitle">伙食费支出预算执行比
                <el-tooltip placement="top">
                  <div slot="content">
                    本学年累计（9月1日至今）<br/>
                    伙食费支出预算执行率=伙食费支出金额/伙食费预算金额
                  </div>
                  <span><img src="@/assets/img/wh.png" alt=""></span>
                </el-tooltip>
              </div>
            </div>
          </li>
          <li>
            <div class="leftIcon">
              <img src="@/assets/img/board/outlay.png" alt="" />
            </div>
            <div class="rightDetail">
              <div class="rate">{{ expendRate.total }}%</div>
              <div class="rateTitle">总支出预算执行率
                <el-tooltip placement="top">
                  <div slot="content">
                    本学年累计（9月1日至今）<br/>
                    总支出预算执行率=总支出金额/总支出目标额
                  </div>
                  <span><img src="@/assets/img/wh.png" alt=""></span>
                </el-tooltip>
              </div>
            </div>
          </li>
          <li>
            <div class="leftIcon">
              <img src="@/assets/img/board/cost.png" alt="" />
            </div>
            <div class="rightDetail">
              <div class="rate">{{ expendRate.human }}%</div>
              <div class="rateTitle">人力成本支出预算执行比
                <el-tooltip placement="top">
                  <div slot="content">
                    本学年累计（9月1日至今）<br/>
                    人力人本支出预算执行率=人力成本支出金额/人力成本支出目标额
                  </div>
                  <span><img src="@/assets/img/wh.png" alt=""></span>
                </el-tooltip>
              </div>
            </div>
          </li>
        </ul>
      </li>
      <li class="topRight">
        <!-- <div class="modelTitle">&nbsp;</div> -->
        <ul>
          <li>
            <div class="leftIcon">
              <img src="@/assets/img/board/income2.png" alt="" />
            </div>
            <div class="rightDetail">
              <div class="rate">{{ incomeRate }}%</div>
              <div class="rateTitle">收入执行率
                <el-tooltip placement="top">
                  <div slot="content">
                    本学年累计（9月1日至今）<br/>
                    收入执行率=到账收入/目标收入
                  </div>
                  <span><img src="@/assets/img/wh.png" alt=""></span>
                </el-tooltip>
              </div>
            </div>
          </li>
          <li>
            <div class="leftIcon">
              <img src="@/assets/img/board/teacher2.png" alt="" />
            </div>
            <div class="rightDetail">
              <div class="rate">60%</div>
              <div class="rateTitle">师生比
                <el-tooltip placement="top">
                  <div slot="content">
                    最新更新数据<br/>
                    师生比=（教师总人数+保育总人数）/幼儿总人数
                  </div>
                  <span><img src="@/assets/img/wh.png" alt=""></span>
                </el-tooltip>
              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <ul class="bottom">
      <li class="half">
        <p class="echartsTitle">疾病防控
          <el-tooltip placement="top">
            <div slot="content">
              本季度累计<br/>
              所有上报数据（全部状态）
            </div>
            <span><img src="@/assets/img/wh.png" alt=""></span>
          </el-tooltip>
        </p>
        <div class="chartsContent" v-show="Object.keys(echartData1).length">
          <div id="echarts1"></div>
        </div>
        <!-- this.echartData2 = data -->
        <div class="noEachartData" v-if="!Object.keys(echartData1).length">
          暂无数据
        </div>
      </li>
      <li class="half">
        <div class="echartsType">
          <p class="echartsTitle">伙食费执行率
            <el-tooltip placement="top">
              <div slot="content">
                本学年累计（9月1日至今）<br/>
                伙食费实际执行率=伙食费支出结算金额/伙食费收入结算金额<br/>
                伙食费支出预算执行率=伙食费支出金额/伙食费预算金额
              </div>
              <span><img src="@/assets/img/wh.png" alt=""></span>
            </el-tooltip>
          </p>
          <el-radio-group v-model="foodProfit" @change="changeMealProfit">
            <el-radio-button label="1">实际支出</el-radio-button>
            <el-radio-button label="2">预算支出</el-radio-button>
          </el-radio-group>
        </div>
        <div
          class="chartsContent long"
          v-show="Object.keys(echartData8).length"
        >
          <div id="echarts8"></div>
        </div>
        <div class="noEachartData" v-if="!Object.keys(echartData8).length">
          暂无数据
        </div>
      </li>
      <!-- <li class="middle">
        <p class="echartsTitle">收入分布</p>
        <div class="chartsContent" v-show="Object.keys(echartData2).length">
          <div id="echarts2"></div>
        </div>
        <div class="noEachartData" v-if="!Object.keys(echartData2).length">
          暂无数据
        </div>
      </li> -->
      <li class="half">
        <div class="echartsType">
          <p class="echartsTitle">招生完成率
            <el-tooltip placement="top">
              <div slot="content">
                本学期累计（学期划分节点3.15、9.15）<br/>
                招生完成率=本期实际招生人数（定金+全费）/招生目标；<br/>
                招生目标=在校生目标-（在校人数-预计流失-预计毕业）；接口返回的在校人数已将预计流失人数、预计毕业人数去掉；
              </div>
              <span><img src="@/assets/img/wh.png" alt=""></span>
            </el-tooltip>
          </p>
          <el-radio-group v-model="studentProfit" @change="changeStudentProfit">
            <el-radio-button label="1">前10名</el-radio-button>
            <el-radio-button label="2">后10名</el-radio-button>
          </el-radio-group>
        </div>
        <div
          class="chartsContent long"
          v-show="Object.keys(echartData9).length"
        >
          <div id="echarts9"></div>
        </div>
        <div class="noEachartData" v-if="!Object.keys(echartData9).length">
          暂无数据
        </div>
      </li>
      <!-- <li class="left">
        <p class="echartsTitle">员工流失率 top10</p>
        <div class="chartsContent" v-show="Object.keys(echartData4).length">
          <div id="echarts4"></div>
        </div>
        <div class="noEachartData" v-if="!Object.keys(echartData4).length">
          暂无数据
        </div>
      </li> -->
      <li class="half">
        <p class="echartsTitle">生均利润（前五名）
          <el-tooltip placement="top">
            <div slot="content">
              本学年累计（9月1日至今）<br/>
              生均利润=总收入/总人数
            </div>
            <span><img src="@/assets/img/wh.png" alt=""></span>
          </el-tooltip>
        </p>
        <div class="chartsContent" v-show="Object.keys(echartData7).length">
          <div id="echarts7"></div>
        </div>
        <div class="noEachartData" v-if="!Object.keys(echartData7).length">
          暂无数据
        </div>
      </li>
      <li class="left">
        <div class="echartsType">
          <p class="echartsTitle">患病率&发病率
            <el-tooltip placement="top">
              <div slot="content">
                本月累计<br/>
                患病率=本月患病总人次/本月出勤总人次；<br/>
                发病率=生病的人数/班级总人数
              </div>
              <span><img src="@/assets/img/wh.png" alt=""></span>
            </el-tooltip>
          </p>
          <div>
            <span class="dotColor huanbing"></span>患病率
            <span class="dotColor fabing"></span>发病率
          </div>
        </div>
        <!-- <p class="echartsTitle"></p> -->
        <div
          class="chartsContent long"
          v-show="Object.keys(echartData5).length"
        >
          <div id="echarts5"></div>
        </div>
        <div class="noEachartData" v-if="!Object.keys(echartData5).length">
          暂无数据
        </div>
      </li>
      <li class="middle">
        <p class="echartsTitle">生均利润（后五名）
          <el-tooltip placement="top">
            <div slot="content">
              本学年累计（9月1日至今）<br/>
              生均利润=总收入/总人数
            </div>
            <span><img src="@/assets/img/wh.png" alt=""></span>
          </el-tooltip>
        </p>
        <div class="chartsContent" v-show="Object.keys(echartData3).length">
          <div id="echarts3"></div>
        </div>
        <div class="noEachartData" v-if="!Object.keys(echartData3).length">
          暂无数据
        </div>
      </li>
      <li class="right">
        <p class="echartsTitle">保险在保率
          <!-- <el-tooltip placement="top">
            <div slot="content">
              生均利润=总收入/总人数
            </div>
            <span><img src="@/assets/img/wh.png" alt=""></span>
          </el-tooltip> -->
        </p>
        <div class="chartsContent" v-show="Object.keys(echartData6).length">
          <div id="echarts6"></div>
        </div>
        <div class="noEachartData" v-if="!Object.keys(echartData6).length">
          暂无数据
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { extension } from "@/utils/validate";
import LTable from "@/components/tableComponent/index.vue";
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    LTable,
  },
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      if (numberAuth.test(valueString)) {
        callback(new Error("只支持数字"));
      } else if (valueString.length > 1 && valueString.substr(0, 1) === "0") {
        callback(new Error("只支持数字，首位不能是0"));
      } else {
        callback();
      }
    };
    return {
      permissionButtonList: [], // 权限按钮list
      isUpdate: false,
      isAdd: false,
      isDelete: false,
      foodProfit: 1,
      studentProfit: 1,
      expendRate: {
        human: 0,
        total: 0,
        wages: 0,
      },
      incomeRate: 0,
      echartData1: {
        name: [],
      },
      echartData2: {
        name: [],
      },
      echartData3: {
        name: [],
      },
      echartData4: {
        name: [],
      },
      echartData5: {
        name: [],
      },
      echartData6: {
        name: [],
      },
      echartData7: {
        name: [],
      },
      echartData8: {
        name: [],
      },
      echartData9: {
        name: [],
      },
      echarts1: null,
      echarts2: null,
      echarts3: null,
      echarts4: null,
      echarts5: null,
      echarts6: null,
      echarts7: null,
      echarts8: null,
      echarts9: null,
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isUpdate = val.indexOf("dict:update") != -1;
          this.isAdd = val.indexOf("dict:save") != -1;
          this.isDelete = val.indexOf("dict:delete") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    this.initData();
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
    setTimeout(() => {
      this.initEcharts1();
      this.initEcharts2();
      this.initEcharts3(2);
      this.initEcharts4();
      this.initEcharts5();
      this.initEcharts6();
      this.initEcharts7(1);
      this.initEcharts8(1);
      this.initEcharts9(1);
    }, 200);
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.echarts1.resize();
      this.echarts2.resize();
      this.echarts3.resize();
      this.echarts4.resize();
      this.echarts5.resize();
      this.echarts6.resize();
      this.echarts7.resize();
      this.echarts8.resize();
      this.echarts9.resize();
    });
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    initData() {
      // 支出预算执行率
      this.$api
        .getExpendRate({
          type: 2,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.expendRate = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      // 收入执行率
      this.$api
        .getIncomeRate({
          type: 2,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.incomeRate = res.data.data.rate;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },

    initEcharts1() {
      this.$api.getStudentContagionReport({ type: 2 }).then((res) => {
        if (res.data.code == "0") {
          let data = res.data.data;
          this.echartData1 = data.xAxis;
          let echarts1 = this.$echarts.init(
            document.getElementById("echarts1")
          );
          // 绘制星期-出险数量对比图图表
          let option1 = {
            tooltip: {
              trigger: "axis",
              backgroundColor: "rgba(9, 12, 21, 0.8)",
              borderColor: "rgba(9, 12, 21, 0.8)",
              textStyle: {
                fontSize: 14,
                textAlign: "center",
                color: "#fff",
              },
              axisPointer: {
                type: "shadow",
              },
            },
            xAxis: {
              type: "category",
              data: data.xAxis,
              triggerEvent: true,
              axisTick: {
                show: false, //不显示坐标轴刻度
              },
              axisLine: {
                show: false, //不显示坐标轴轴线
              },
              axisLabel: {
                rotate: 30,
                textStyle: {
                  //改变xy轴上文字的颜色
                  color: "#A8A6B9",
                },
                margin: 8,
                formatter:function(params){
                  var val="";
                  if(params.length >4){
                      val = params.substr(0,4)+'...';
                      return val;
                  }else{
                      return params;
                  }
                }
              },
            },
            yAxis: {
              type: "value",
              axisLabel: {
                textStyle: {
                  color: "#A8A6B9",
                },
              },
              minInterval: 1,
            },
            grid: {
              left: 60,
              bottom: 40,
              // left:50,
            },
            series: [
              {
                data: data.yAxis,
                type: "bar",
                barWidth: 20, //柱图宽度
                itemStyle: {
                  //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                  normal: {
                    //柱形图圆角，初始化效果
                    barBorderRadius: [4, 4, 0, 0],
                    color: "#004DA0",
                  },
                },
              },
            ],
          };
          extension(echarts1);
          echarts1.setOption(option1);
          this.echarts1 = echarts1;

        } else {
          this.$message.error(res.data.msg);
        }
      });

    },

    initEcharts2() {
      this.$api
        .getIncomeDistribute({
          type: 2,
        })
        .then((res) => {
          if (res.data.code == "0") {
            let data = res.data.data;
            this.echartData2 = data;
            let echarts2 = this.$echarts.init(
              document.getElementById("echarts2")
            );
            // 绘制星期-出险数量对比图图表
            let option2 = {
              title: {
                left: "center",
                top: 20,
                textStyle: {
                  color: "#ccc",
                },
              },
              tooltip: {
                trigger: "item",
                show: false,
              },
              color: ["#004DA0", "#E7C69C", "#2F5ECB", "#93252A", "#00AB9F"],
              series: [
                {
                  name: "Access From",
                  type: "pie",
                  radius: "55%",
                  center: ["50%", "50%"],
                  // startAngle:-90,
                  data: data.sort(function (a, b) {
                    return b.value - a.value;
                  }),
                  roseType: "radius",
                  label: {
                    // show:false,
                    color: "#6C7293",
                    // formatter: '{a| {b} }\n{b| {c} }',
                    formatter: " {b}\n{c}",
                  },
                  labelLine: {
                    lineStyle: {
                      color: "#EAEBF0",
                    },
                  },
                },
              ],
            };
            echarts2.setOption(option2);
            this.echarts2 = echarts2;
          }
        });
    },
    initEcharts3(type) {
      this.$api
        .getProfit({
          menu: 2,
          type: type,
        })
        .then((res) => {
          if (res.data.code == "0") {
            let data = res.data.data;
            this.echartData3 = data;
            if (Object.keys(this.echartData3).length == 0) {
              return;
            }
            let echarts3 = this.$echarts.init(
              document.getElementById("echarts3")
            );
            // 绘制星期-出险数量对比图图表
            let option3 = {
              tooltip: {
                trigger: "axis",
                backgroundColor: "rgba(9, 12, 21, 0.8)",
                borderColor: "rgba(9, 12, 21, 0.8)",
                textStyle: {
                  fontSize: 14,
                  textAlign: "center",
                  color: "#fff",
                },
                axisPointer: {
                  type: "shadow",
                },
                formatter: function (val) {
                  console.log(val);
                  return (
                    val[0].name +
                    '<span style="width:20px;display: inline-block;"></span>' +
                    val[0].value
                  );
                },
              },
              xAxis: {
                type: "value",
                triggerEvent: true,
                axisTick: {
                  show: false, //不显示坐标轴刻度
                },
                axisLine: {
                  show: false, //不显示坐标轴轴线
                },
                axisLabel: {
                  // rotate:30,
                  textStyle: {
                    //改变xy轴上文字的颜色
                    color: "#A8A6B9",
                  },
                     margin: 8,
                  formatter:function(params){
                    var val="";
                    if(params.length >4){
                        val = params.substr(0,4)+'...';
                        return val;
                    }else{
                        return params;
                    }
                  }
                },
              },
              yAxis: {
                type: "category",
                data: data.post.reverse(),
                triggerEvent: true,
                axisTick: {
                  show: false, //不显示坐标轴刻度
                },
                axisLine: {
                  show: false, //不显示坐标轴轴线
                },
                axisLabel: {
                  textStyle: {
                    color: "#A8A6B9",
                  },
                  margin: 8,
                  formatter:function(params){
                    var val="";
                    if(params.length >4){
                        val = params.substr(0,4)+'...';
                        return val;
                    }else{
                        return params;
                    }
                  }
                },
             
              },
              grid: {
                bottom: 40,
                left: 70,
              },
              series: [
                {
                  data: data.userNumber.reverse(),
                  type: "bar",
                  barWidth: 20, //柱图宽度
                  showBackground: true,
                  backgroundStyle: {
                    color: "#F3F3FB",
                  },
                  itemStyle: {
                    //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                    normal: {
                      //柱形图圆角，初始化效果
                      barBorderRadius: [0, 4, 4, 0],
                      color: "#E7C69C",
                      label: {
                        formatter: "{c}",
                        show: true,
                        position: "right",
                        textStyle: {
                          fontSize: "14",
                          color: "#9290B1",
                        },
                      },
                    },
                  },
                },
              ],
            };
            extension(echarts3);
            echarts3.setOption(option3);
            this.echarts3 = echarts3;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    initEcharts4() {
      this.$api
        .getStaffLeaveRate({
          type: 2,
        })
        .then((res) => {
          if (res.data.code == "0") {
            // console.log(res.data.data)
            let data = res.data.data;
            this.echartData4 = data;
            // 颜色值顺序 本科，大专，硕士及以上，中专及以下
            let echarts4 = this.$echarts.init(
              document.getElementById("echarts4")
            );
            let option4 = {
              tooltip: {
                trigger: "axis",
                backgroundColor: "rgba(9, 12, 21, 0.8)",
                borderColor: "rgba(9, 12, 21, 0.8)",
                textStyle: {
                  fontSize: 14,
                  textAlign: "center",
                  color: "#fff",
                },
                axisPointer: {
                  type: "shadow",
                },
                formatter: function (val) {
                  console.log(val);
                  return (
                    val[0].marker +
                    '<span style="width:20px;display: inline-block;"></span>' +
                    val[0].name +
                    '<span style="width:20px;display: inline-block;"></span>' +
                    val[0].value +
                    "%"
                  );
                },
              },
              xAxis: {
                type: "value",
                axisTick: {
                  show: false, //不显示坐标轴刻度
                },
                axisLine: {
                  show: false, //不显示坐标轴轴线
                },
                axisLabel: {
                  // rotate:30,
                  textStyle: {
                    //改变xy轴上文字的颜色
                    color: "#A8A6B9",
                  },
                },
              },
              yAxis: {
                type: "category",
                triggerEvent: true,
                axisLabel: {
                  textStyle: {
                    color: "#A8A6B9",
                  },
                  margin: 8,
                  formatter:function(params){
                    var val="";
                    if(params.length >4){
                        val = params.substr(0,4)+'...';
                        return val;
                    }else{
                        return params;
                    }
                  }
                },
                axisTick: {
                  show: false, //不显示坐标轴刻度
                },
                axisLine: {
                  show: false, //不显示坐标轴轴线
                },
                data: data.name,
              },
              grid: {
                bottom: 40,
                left: 80,
              },
              series: [
                {
                  data: data.rate,
                  type: "bar",
                  barWidth: 20, //柱图宽度
                  showBackground: true,
                  backgroundStyle: {
                    color: "#F3F3FB",
                  },
                  itemStyle: {
                    //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                    normal: {
                      //柱形图圆角，初始化效果
                      barBorderRadius: [0, 4, 4, 0],
                      color: function (params) {
                        if (params.dataIndex == 6) {
                          return "#E7C69C";
                        } else {
                          return "#004DA0";
                        }
                      },
                      label: {
                        formatter: "{c}%",
                        show: true,
                        position: "right",
                        textStyle: {
                          fontSize: "14",
                          color: "#9290B1",
                        },
                      },
                    },
                  },
                },
              ],
            };
            extension(echarts4);
            echarts4.setOption(option4);
            this.echarts4 = echarts4;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    initEcharts5() {
      // getStudentDiseaseRate
      this.$api
        .getStudentDiseaseRate({
          type: "2",
        })
        .then((res) => {
          if (res.data.code == "0") {
            // console.log(res.data.data)
            let data = res.data.data;
            this.echartData5 = data;
            // 颜色值顺序 本科，大专，硕士及以上，中专及以下
            let echarts5 = this.$echarts.init(
              document.getElementById("echarts5")
            );
            let option5 = {
              tooltip: {
                trigger: "axis",
                backgroundColor: "rgba(9, 12, 21, 0.8)",
                borderColor: "rgba(9, 12, 21, 0.8)",
                textStyle: {
                  fontSize: 14,
                  textAlign: "center",
                  color: "#fff",
                },
                axisPointer: {
                  type: "shadow",
                },
                formatter: function (params) {
                  var relVal = params[0].name;
                  var names = ["患病率", "发病率"];
                  for (var i = 0; i < params.length; i++) {
                    relVal +=
                      "<br/>" +
                      params[i].marker +
                      names[i] +
                      " " +
                      params[i].value +
                      "%";
                  }
                  return relVal;
                },
              },
              xAxis: {
                type: "category",
                data: data.company,
                triggerEvent: true,
                axisTick: {
                  show: false, //不显示坐标轴刻度
                },
                axisLine: {
                  show: false, //不显示坐标轴轴线
                },
                axisLabel: {
                  rotate: 50,
                  textStyle: {
                    //改变xy轴上文字的颜色
                    color: "#A8A6B9",
                  },
                  margin: 8,
                  formatter:function(params){
                    var val="";
                    if(params.length >4){
                        val = params.substr(0,4)+'...';
                        return val;
                    }else{
                        return params;
                    }
                  }
                },
              },
              legend: {
                // orient: 'vertical',
                show: true,
                // top: 'center',
                // left: 'center',
                icon: "circle",
                // textStyle:{
                //   color:'#9290B1',
                //   lineHeight:30,
                //   fontSize:14,
                // },
              },
              yAxis: {
                type: "value",
                axisLabel: {
                  textStyle: {
                    color: "#A8A6B9",
                  },
                },
                // minInterval: 1
                // axisTick: {
                //   show: false  //不显示坐标轴刻度
                // },
                // axisLine: {
                //   show: false  //不显示坐标轴轴线
                // },
              },
              grid: {
                bottom: 70,
                // left:80,
                // containLabel: true
              },
              series: [
                {
                  data: data.rate[0],
                  type: "bar",
                  barWidth: 6, //柱图宽度
                  itemStyle: {
                    //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                    normal: {
                      //柱形图圆角，初始化效果
                      barBorderRadius: [2, 2, 0, 0],
                      color: "#004DA0",
                    },
                  },
                },
                {
                  data: data.rate[1],
                  type: "bar",
                  barWidth: 6, //柱图宽度
                  itemStyle: {
                    //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                    normal: {
                      //柱形图圆角，初始化效果
                      barBorderRadius: [2, 2, 0, 0],
                      color: "#E7C69C",
                    },
                  },
                },
              ],
            };
            extension(echarts5);//这个方法是用来处理鼠标悬浮显示全部内容的
            echarts5.setOption(option5);
            this.echarts5 = echarts5;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    initEcharts6() {
      this.$api
        .getOperateInsure({
          type: 3,
        })
        .then((res) => {
          if (res.data.code == "0") {
            let data = res.data.data;
            this.echartData6 = data;
            let echarts6 = this.$echarts.init(
              document.getElementById("echarts6")
            );
            // 绘制星期-出险数量对比图图表
            let option6 = {
              tooltip: {
                trigger: "item",
                show: false,
              },
              legend: {
                orient: "vertical",
                show: true,
                top: 80,
                left: "65%",
                icon: "circle",
                textStyle: {
                  color: "#9290B1",
                  lineHeight: 30,
                  fontSize: 14,
                },
                selectedMode: false,
              },
              color: ["#004DA0", "#C5C3D8"],
              series: [
                {
                  name: "Access From",
                  type: "pie",
                  radius: ["45%", "60%"],
                  center: ["35%", "40%"],
                  avoidLabelOverlap: false,
                  label: {
                    show: false,
                    position: "center",
                  },
                  emphasis: {
                    label: {
                      show: true,
                      formatter: function (params) {
                        let ele = `{a|${params.percent}%} \n {b| ${params.name}}`;
                        return ele;
                      },
                      rich: {
                        a: {
                          fontSize: 20,
                          textAlign: "center",
                          color: "#000",
                        },
                        b: {
                          fontSize: 12,
                          textAlign: "center",
                          color: "#6C7293",
                        },
                      },
                    },
                  },
                  labelLine: {
                    show: false,
                  },
                  data: [
                    { value: data.inInsure, name: "在保率" },
                    { value: data.outInsure, name: "未在保率" },
                  ],
                },
              ],
            };
            echarts6.setOption(option6);
            let dataCountData = [
              { value: data.inInsure, name: "在保率" },
              { value: data.outInsure, name: "未在保率" },
            ];
            let dataCount = dataCountData.length;
            // 设置默认展示，seriesIndex：系列名，dataIndex：当前索引
            echarts6.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: 0,
            });
            // 鼠标进入高亮当前，取消其他高亮
            echarts6.on("mouseover", (e) => {
              console.log(e, "8989");
              echarts6.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: e.dataIndex,
              });
              // 根据当前option中data的length，遍历取消其他高亮
              for (let i = 0; i < dataCount; i++) {
                if (i != e.dataIndex) {
                  echarts6.dispatchAction({
                    type: "downplay",
                    seriesIndex: 0,
                    dataIndex: i,
                  });
                }
              }
            });
            //鼠标移出后显示之前默认高亮的那块，其他取消高亮
            echarts6.on("mouseout", (e) => {
              console.log(e, "你猜");
              if (e.dataIndex !== 0) {
                echarts6.dispatchAction({
                  type: "highlight",
                  seriesIndex: 0,
                  dataIndex: e.dataIndex,
                });
              } else {
                echarts6.dispatchAction({
                  type: "highlight",
                  seriesIndex: 0,
                  dataIndex: 0,
                });
              }
            });
            this.echarts6 = echarts6;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    initEcharts7(type) {
      this.$api
        .getProfit({
          menu: 2,
          type: type,
        })
        .then((res) => {
          if (res.data.code == "0") {
            let data = res.data.data;
            this.echartData7 = data;
            if (Object.keys(this.echartData7).length == 0) {
              return;
            }
            // console.log(data)
            let echarts7 = this.$echarts.init(
              document.getElementById("echarts7")
            );
            // 绘制星期-出险数量对比图图表
            let option7 = {
              tooltip: {
                trigger: "axis",
                backgroundColor: "rgba(9, 12, 21, 0.8)",
                borderColor: "rgba(9, 12, 21, 0.8)",
                textStyle: {
                  fontSize: 14,
                  textAlign: "center",
                  color: "#fff",
                },
                axisPointer: {
                  type: "shadow",
                },
                formatter: function (val) {
                  console.log(val);
                  return (
                    val[0].name +
                    '<span style="width:20px;display: inline-block;"></span>' +
                    val[0].value
                  );
                },
              },
              xAxis: {
                type: "value",
                axisTick: {
                  show: false, //不显示坐标轴刻度
                },
                axisLine: {
                  show: false, //不显示坐标轴轴线
                },
                axisLabel: {
                  // rotate:30,
                  textStyle: {
                    //改变xy轴上文字的颜色
                    color: "#A8A6B9",
                  },
                },
              },
              yAxis: {
                type: "category",
                data: data.post.reverse(),
                triggerEvent: true,
                axisLabel: {
                  textStyle: {
                    color: "#A8A6B9",
                  },
                  margin: 8,
                  formatter:function(params){
                    var val="";
                    if(params.length >4){
                        val = params.substr(0,4)+'...';
                        return val;
                    }else{
                        return params;
                    }
                  }
                },
                axisTick: {
                  show: false, //不显示坐标轴刻度
                },
                axisLine: {
                  show: false, //不显示坐标轴轴线
                },
              },
              grid: {
                bottom: 40,
                left: 80,
              },
              series: [
                {
                  data: data.userNumber.reverse(),
                  type: "bar",
                  barWidth: 20, //柱图宽度
                  showBackground: true,
                  backgroundStyle: {
                    color: "#F3F3FB",
                  },
                  itemStyle: {
                    //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                    normal: {
                      //柱形图圆角，初始化效果
                      barBorderRadius: [0, 4, 4, 0],
                      color: "#004DA0",
                      label: {
                        formatter: "{c}",
                        show: true,
                        position: "right",
                        textStyle: {
                          fontSize: "14",
                          color: "#9290B1",
                        },
                      },
                    },
                  },
                },
              ],
            };
            extension(echarts7);
            echarts7.setOption(option7);
            this.echarts7 = echarts7;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    initEcharts8(type) {
      this.$api
        .getWagesExecute({
          menu: 2,
          type: type,
        })
        .then((res) => {
          if (res.data.code == "0") {
            let data = res.data.data;
            this.echartData8 = data;
            let echarts8 = this.$echarts.init(
              document.getElementById("echarts8")
            );
            // 绘制星期-出险数量对比图图表
            let option8 = {
              tooltip: {
                trigger: "axis",
                backgroundColor: "rgba(9, 12, 21, 0.8)",
                borderColor: "rgba(9, 12, 21, 0.8)",
                textStyle: {
                  fontSize: 14,
                  textAlign: "center",
                  color: "#fff",
                },
                axisPointer: {
                  type: "shadow",
                },
                formatter: function (val) {
                  console.log(val);
                  return (
                    val[0].name +
                    '<span style="width:20px;display: inline-block;"></span>' +
                    val[0].value +
                    "%"
                  );
                },
              },
              xAxis: {
                type: "category",
                data: data.post,
                triggerEvent: true,
                axisTick: {
                  show: false, //不显示坐标轴刻度
                },
                axisLine: {
                  show: false, //不显示坐标轴轴线
                },
                axisLabel: {
                  rotate: 50,
                  textStyle: {
                    //改变xy轴上文字的颜色
                    color: "#A8A6B9",
                  },
                  margin: 8,
                  formatter:function(params){
                    var val="";
                    if(params.length >4){
                        val = params.substr(0,4)+'...';
                        return val;
                    }else{
                        return params;
                    }
                  }
                },
              },
              yAxis: {
                type: "value",
                axisLabel: {
                  textStyle: {
                    color: "#A8A6B9",
                  },
                  formatter: function (value) {
                    return value + "%";
                  },
                },
                axisTick: {
                  show: false, //不显示坐标轴刻度
                },
                axisLine: {
                  show: false, //不显示坐标轴轴线
                },
              },
              grid: {
                bottom: 80,
                // left:80,
              },
              series: [
                {
                  data: data.userNumber,
                  type: "bar",
                  barWidth: 6, //柱图宽度
                  itemStyle: {
                    //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                    normal: {
                      //柱形图圆角，初始化效果
                      barBorderRadius: [2, 2, 0, 0],
                      color: function (params) {
                        if (params.value > 100) {
                          return "#E7C69C";
                        } else if (params.dataIndex == 4) {
                          return "#00AB9F";
                        } else {
                          return "#004DA0";
                        }
                      },
                    },
                  },
                },
              ],
            };
            extension(echarts8);
            echarts8.setOption(option8);
            this.echarts8 = echarts8;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    initEcharts9(type) {
      this.$api
        .getEnrollRate({
          menu: 2,
          type: type,
        })
        .then((res) => {
          if (res.data.code == "0") {
            let data = res.data.data;
            this.echartData9 = data;
            let echarts9 = this.$echarts.init(
              document.getElementById("echarts9")
            );
            // 绘制星期-出险数量对比图图表
            let option9 = {
              tooltip: {
                trigger: "axis",
                backgroundColor: "rgba(9, 12, 21, 0.8)",
                borderColor: "rgba(9, 12, 21, 0.8)",
                textStyle: {
                  fontSize: 14,
                  textAlign: "center",
                  color: "#fff",
                },
                axisPointer: {
                  type: "shadow",
                },
                formatter: function (val) {
                  console.log(val);
                  return (
                    val[0].name +
                    '<span style="width:20px;display: inline-block;"></span>' +
                    val[0].value +
                    "%"
                  );
                },
              },
              xAxis: {
                type: "category",
                data: data.post,
                axisTick: {
                  show: false, //不显示坐标轴刻度
                },
                axisLine: {
                  show: false, //不显示坐标轴轴线
                },
                axisLabel: {
                  rotate: 50,
                  textStyle: {
                    //改变xy轴上文字的颜色
                    color: "#A8A6B9",
                  },
                },
              },
              yAxis: {
                type: "value",
                axisLabel: {
                  textStyle: {
                    color: "#A8A6B9",
                  },
                },
                minInterval: 1,
                axisTick: {
                  show: false, //不显示坐标轴刻度
                },
                axisLine: {
                  show: false, //不显示坐标轴轴线
                },
              },
              grid: {
                bottom: 70,
                left: 40,
              },
              series: [
                {
                  data: data.userNumber,
                  type: "bar",
                  barWidth: 20, //柱图宽度
                  itemStyle: {
                    //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                    normal: {
                      //柱形图圆角，初始化效果
                      barBorderRadius: [4, 4, 0, 0],
                      color: function (params) {
                        if (params.value > 100) {
                          return "#E7C69C";
                        } else {
                          return "#004DA0";
                        }
                      },
                    },
                  },
                },
              ],
            };
            echarts9.setOption(option9);
            this.echarts9 = echarts9;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    changeMealProfit(val) {
      this.initEcharts8(val);
    },
    changeStudentProfit(val) {
      this.initEcharts9(val);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.echarts1.resize();
      this.echarts2.resize();
      this.echarts3.resize();
      this.echarts4.resize();
      this.echarts5.resize();
      this.echarts6.resize();
      this.echarts7.resize();
      this.echarts8.resize();
      this.echarts9.resize();
    });
  },
};
</script>

<style lang="scss" scoped>
.dict {
  background-color: #f4f4fc;
  min-height: calc(100% - 64px);
  min-width: 1200px;
  overflow-x: scroll;
  padding: 34px 30px 30px 30px;
  .tableList {
    height: calc(100% - 62px);
  }
  .boardTitle {
    color: #3d3e4f;
    font-size: 22px;
    font-weight: bold;
  }
  .top {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    li {
      width: 28%;
      // height: 172px;
      background-color: #fff;
      background: #ffffff;
      box-shadow: 0px 0px 3px rgba(37, 57, 177, 0.04);
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 20px;
      box-sizing: border-box;
      &.topRight {
        // width: 66.5%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .modelTitle {
          font-size: 20px;
        }
        ul {
          li {
            width: 50%;
            &:first-child .leftIcon {
              background: #e7c69c;
            }
            &:last-child .leftIcon {
              background: #004da0;
            }
          }
        }
      }
      &.topLeft {
        width: 70.5%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .modelTitle {
          font-size: 20px;
        }
      }
      .leftIcon {
        width: 52px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        img {
          width: 32px;
        }
      }
      &:nth-child(1) .leftIcon {
        background: #93252a;
      }
      &:nth-child(2) .leftIcon {
        background: #00ab9f;
      }
      &:nth-child(3) .leftIcon {
        background: #004da0;
      }
      &:nth-child(4) .leftIcon {
        background: #e7c69c;
      }
      .rightDetail {
        margin-left: 10px;
      }
      .rate {
        color: #231f32;
        font-size: 30px;
      }
      .rateTitle {
        color: #a7a5b9;
        font-size: 20px;
        margin-top: 6px;
      }
      ul {
        width: 100%;
        height: 100%;
        // padding-top: 20px;
        display: flex;
        li {
          width: 33%;
          height: 100%;
          padding: 0;
        }
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      margin-top: 30px;
      width: 33%;
      height: 380px;
      background: #ffffff;
      box-shadow: 0px 0px 3px rgba(37, 57, 177, 0.04);
      border-radius: 8px;
      &.left {
        width: 37%;
      }
      &.middle {
        width: 32%;
      }
      &.right {
        width: 28%;
      }
      &.bottomCenter {
        width: 34%;
      }
      &.half{
        width: 49.2%;
      }
      .chartsContent {
        width: calc(100% - 40px);
        height: calc(100% - 120px);
        padding: 20px;
        &.long {
          height: calc(100% - 76px);
        }
      }
      .echartsTitle {
        padding: 28px;
        color: #3d3e4f;
        font-size: 18px;
      }
      #echarts1,
      #echarts2,
      #echarts3,
      #echarts6,
      #echarts4,
      #echarts5,
      #echarts7,
      #echarts8,
      #echarts9 {
        width: 100%;
        height: 100%;
      }
      // #echarts5,
      // #echarts8,
      // #echarts9 {
      //   margin-top: 28px;
      //   height: calc(100% - 28px);
      // }
      .echartsType {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: center;
        margin-top: 18px;
        padding: 0 20px;
        .echartsTitle {
          padding: 0 0px;
        }
        color: #9290b1;
        .dotColor {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 8px;
        }
        font-size: 14px;
        .huanbing {
          background-color: #004da0;
        }
        .fabing {
          background-color: #e7c69c;
          margin-left: 27px;
        }
      }
      .echartsDesc {
        display: flex;
        width: 70%;
        justify-content: center;
        margin: 0 auto;
        flex-wrap: wrap;
        align-items: center;
        .itemDesc {
          width: 50%;
          margin-top: 10px;
          .dotColor {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
          }
          .benke {
            background: #004da0;
          }
          .dazhuan {
            background: #e7c69c;
          }
          .shuoshi {
            background: #00ab9f;
          }
          .zhongzhuan {
            background: #93252a;
          }
          .optionTitle {
            width: 6em;
            font-size: 12px;
            display: inline-block;
            color: #6c7293;
            margin-left: 5px;
          }
          .peopleNum {
            color: #6c7293;
            font-size: 12px;
          }
        }
      }
    }
  }
}
//  and (max-width: 1920px)
// @media screen and (min-width: 1800px) {
//   .dict {
//     .boardTitle {
//       color: #3d3e4f;
//       font-size: 22px;
//       font-weight: bold;
//     }
//     .top {
//       margin-top: 30px;
//       li {
//         padding: 40px;
//         .leftIcon {
//           width: 92px;
//           height: 92px;
//         }
//         .rightDetail {
//           margin-left: 10px;
//         }
//         .rate {
//           font-size: 30px;
//         }
//         .rateTitle {
//           font-size: 20px;
//           margin-top: 10px;
//         }
//       }
//     }
//   }
// }
/deep/.el-radio-button__inner {
  padding: 8px;
}
.noEachartData {
  height: calc(100% - 74px);
  width: 100%;
  // height: 100%;
  // text-align: center;
  color: #999;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /deep/div {
    display: none;
  }
}
</style>
